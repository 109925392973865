import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {ArrowRight} from '@styled-icons/bootstrap/ArrowRight';
import {Link as LinkIcon} from '@styled-icons/ionicons-outline/Link';
import Theme from '../../../Theme';
import * as AppActions from '../../../AppActions';
import {useOutlet} from 'reconnect.js';

function Landing(props) {
  const {custom_layout} = props.landing;
  const {carousel = [{}], bottom_links = []} = custom_layout || {};
  const [carouselIdx, setCarouselIdx] = React.useState(0);
  const carouselRef = React.useRef();
  const [dimension] = useOutlet('dimension');
  const carouselHeight = dimension?.innerWidth / 3.6 || 300;

  return (
    <Wrapper>
      <div style={{position: 'relative'}}>
        <Ant.Carousel
          autoplay={true}
          autoplaySpeed={5000}
          dots={false}
          draggable={true}
          ref={carouselRef}
          afterChange={(idx) => {
            setCarouselIdx(idx);
          }}>
          {carousel.map((it, idx) => {
            return (
              <div key={idx}>
                <div
                  className="revtel-carousel-item"
                  style={{height: carouselHeight}}
                  onClick={() => {
                    if (it.url) {
                      window.open(it.url, '_blank');
                    }
                  }}>
                  {it.image && <img src={it.image} alt="carousel-item" />}
                </div>
              </div>
            );
          })}
        </Ant.Carousel>

        <div className="revtel-carousel-dots">
          {carousel.map((_, idx) => {
            const selected = idx === carouselIdx;
            return (
              <div
                key={idx}
                style={{backgroundColor: selected ? 'white' : '#ccc'}}
                onClick={() => {
                  if (carouselRef.current) {
                    carouselRef.current.goTo(idx);
                  }
                }}
              />
            );
          })}
        </div>

        <div
          className="revtel-carousel-next"
          style={{
            left: 0,
            background:
              'linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0))',
          }}
          onClick={() => {
            console.log('DBGGG', carouselIdx, carousel.length);
            const nextIdx =
              carouselIdx - 1 < 0 ? carousel.length - 1 : carouselIdx - 1;
            console.log('DBGGG', nextIdx);
            carouselRef.current.goTo(nextIdx);
          }}>
          <div>{'<'}</div>
        </div>
        <div
          className="revtel-carousel-next"
          style={{
            right: 0,
            background:
              'linear-gradient(to left, rgba(0,0,0,0.5), rgba(0,0,0,0))',
          }}
          onClick={() => {
            const nextIdx = (carouselIdx + 1) % carousel.length;
            carouselRef.current.goTo(nextIdx);
          }}>
          <div>{'>'}</div>
        </div>
      </div>
      <NewsSection />
      <ActivitySection />
      <LinkSection links={bottom_links} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 80px;
  padding-top: var(--topNavBarHeight);

  .revtel-carousel-item {
    margin: 0;
    // height: 330px;
    background: #ccc;
    position: relative;
    cursor: pointer;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .revtel-carousel-dots {
    position: relative;
    margin-top: -30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    & > * {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: pink;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
    }
  }

  .revtel-carousel-next {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    height: 100%;
    width: 75px;
    cursor: pointer;
    & > * {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #eee;
      border-radius: 50%;
      color: #666;
      font-size: 18px;
    }
  }
`;

function NewsSection(props) {
  const NewsType = {
    important: {
      label: '重要公告',
    },
    internal: {
      label: '會務訊息',
    },
    external: {
      label: '會外新訊',
    },
  };
  const [selectedTab, setSelectedTab] = React.useState('important');
  const [fetchResp, setFetchResp] = React.useState({results: []});

  React.useEffect(() => {
    async function fetchData() {
      setFetchResp(
        await JStorage.fetchDocuments(
          'internal_news',
          {
            type: selectedTab,
            active: true,
          },
          ['-date'],
          {offset: 0, limit: 5},
          null,
          {token: null},
        ),
      );
    }

    fetchData();
  }, [selectedTab]);

  return (
    <NewsSectionWrapper>
      <h2>最新消息</h2>
      <ul className="tabs">
        {Object.keys(NewsType).map((it, idx) => {
          const selected = it === selectedTab;
          const selectedStyle = {
            color: 'white',
            backgroundColor: Theme.colors.main,
          };
          return (
            <li
              key={idx}
              style={selected ? selectedStyle : {}}
              onClick={() => setSelectedTab(it)}>
              {NewsType[it].label}
            </li>
          );
        })}
      </ul>

      <div style={{alignSelf: 'stretch'}}>
        <ul className="records">
          {fetchResp?.results.map((it, idx) => {
            return (
              <li
                key={idx}
                onClick={() => {
                  if (it.link) {
                    window.open(it.link, '_blank');
                  } else {
                    AppActions.navigate(
                      `/news/${selectedTab}/?action=detail&id=${it.id}`,
                    );
                  }
                }}>
                <div className="title">{it.title}</div>
                <div className="date">{it.date || '---'}</div>
              </li>
            );
          })}
        </ul>
      </div>

      <Ant.Button
        type="link"
        onClick={() => {
          if (selectedTab === 'internal') {
            AppActions.navigate('/news/internal/');
          } else if (selectedTab === 'external') {
            AppActions.navigate('/news/external/');
          } else {
            AppActions.navigate('/news/important/');
          }
        }}>
        更多{NewsType[selectedTab].label}
        <ArrowRight size={20} style={{marginTop: -2, marginLeft: 8}} />
      </Ant.Button>
    </NewsSectionWrapper>
  );
}

const NewsSectionWrapper = styled.section`
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  & ul.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px;

    & > * {
      border-radius: 30px;
      padding: 10px 20px;
      background-color: #f0f4f8;
      cursor: pointer;
    }
  }

  & ul.records {
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    max-width: 880px;

    & > * {
      display: flex;
      padding: 10px 0;
      border-bottom: 2px solid #eee;
      cursor: pointer;
      gap: 10px;
      & > *.title {
        flex: 1 1 200px;
      }
      & > *.date {
        flex: 0 0 100px;
      }
    }
  }
`;

function ActivitySection(props) {
  const [fetchResp, setFetchResp] = React.useState({results: []});

  React.useEffect(() => {
    async function fetchData() {
      setFetchResp(
        await JStorage.fetchDocuments(
          'activity',
          {},
          ['-date'],
          {offset: 0, limit: 3},
          null,
          {token: null},
        ),
      );
    }

    fetchData();
  }, []);

  return (
    <ActivitySectionWrapper>
      <h2>活動花絮</h2>
      <div style={{alignSelf: 'stretch'}}>
        <div className="activity-grid">
          {fetchResp.results.map((it, idx) => {
            const firstImg =
              it.images?.length > 0
                ? it.images[0].image
                : '/images/site-logo.png';

            return (
              <div
                key={idx}
                onClick={() => {
                  AppActions.navigate(
                    `/news/activities/?action=detail&id=${it.id}`,
                  );
                }}>
                <img src={firstImg} alt="activity-item" />
                <div className="info">
                  <div>{it.date}</div>
                  <h3>{it.name}</h3>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Ant.Button
        type="link"
        onClick={() => {
          AppActions.navigate(`/news/activities/`);
        }}>
        更多活動花絮
        <ArrowRight size={20} style={{marginTop: -2, marginLeft: 8}} />
      </Ant.Button>
    </ActivitySectionWrapper>
  );
}

const ActivitySectionWrapper = styled.section`
  padding: 60px 20px;
  background-color: #f0f4f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  & .activity-grid {
    max-width: 1024px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    & > * {
      cursor: pointer;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.33);
      border-radius: 4px;
      background-color: white;
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      & > img {
        width: 100%;
        height: 180px;
        object-fit: cover;
        background-color: #eee;
      }
      & > .info {
        border-top: 1px solid #ccc;
        padding: 10px;
      }
    }
  }
`;

function LinkSection(props) {
  return (
    <LinkSectionWrapper>
      <h2>法規資訊</h2>
      <div style={{alignSelf: 'stretch'}}>
        <ul className="links">
          {props.links.map((it, idx) => {
            return (
              <li key={idx} onClick={() => window.open(it.url)}>
                {it.text}
                <LinkIcon size={20} style={{marginLeft: 6, marginTop: -2}} />
              </li>
            );
          })}
        </ul>
      </div>
    </LinkSectionWrapper>
  );
}

const LinkSectionWrapper = styled.section`
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  & ul.links {
    max-width: 720px;
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    & > * {
      border-radius: 30px;
      padding: 8px 12px;
      border: 1px solid ${Theme.colors.main};
      background: white;
      cursor: pointer;
      color: ${Theme.colors.main};
    }
  }
`;

export default Landing;
